import { createSlice } from "@reduxjs/toolkit";
import { SanitizerGelProduct } from "../../Api/Api"; // Import API function

const sanitizerGelSlice = createSlice({
    name: "sanitizerGel",
    initialState: {
        products: [],  // Ensure initial state is an array
        loading: false,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(SanitizerGelProduct.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(SanitizerGelProduct.fulfilled, (state, action) => {
                //console.log("Data Received in Redux:", action.payload);
                state.products = action.payload; // Ensure this is an array
                state.loading = false;
            })
            .addCase(SanitizerGelProduct.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

export default sanitizerGelSlice.reducer;
