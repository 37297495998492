import { configureStore } from "@reduxjs/toolkit";
import  userSlice  from "./Slices/userSlice";
import  bannerSlice  from "./Slices/bannerSlice";
import categorySclice from "./Slices/categorySclice";
import productSlice from "./Slices/productSlice";
import detailsSlice from "./Slices/productEntrySlice";
import sizeSlice from "./Slices/sizeSlice";
import cartSlice from "./Slices/cartSlice";
import imagemagnifySlice from "./Slices/imagemagnifySlice";
import getAddressSlice from "./Slices/getAddressSlice";
import getwishlistSlice from "./Slices/getwishlistSlice";
import gethistorySlice from "./Slices/gethistorySlice";
import BrandsSlice from "./Slices/BrandsSlice";
import handWashReducer from "./Slices/handWashSlice";
import toiletCleanerReducer from "./Slices/toiletCleanerSlice";
import floorCleanerReducer from "./Slices/floorCleanerSlice";
import surfaceCleanerReducer from "./Slices/surfaceCleanerSlice";
import dishWashGelReducer from "./Slices/dishWashGelSlice";
import sanitizerGelReducer from "./Slices/sanitizerGelSlice";
import bodyWashReducer from "./Slices/bodyWashSlice";
import ratingReducer from "./Slices/ratingSlice";


const store = configureStore({
    reducer: {
        users: userSlice,
        banner:bannerSlice,
        category:categorySclice,
        product:productSlice,
        productdetails:detailsSlice,
        size:sizeSlice,
        cart:cartSlice,
        imagemagnify:imagemagnifySlice,
        address:getAddressSlice,
        wishlist:getwishlistSlice,
        history:gethistorySlice,
        Brand:BrandsSlice,
        handWash: handWashReducer,
        toiletCleaner: toiletCleanerReducer,
        floorCleaner: floorCleanerReducer,
        surfaceCleaner: surfaceCleanerReducer,
        dishWashGel: dishWashGelReducer,
        sanitizerGel: sanitizerGelReducer,
        bodyWash: bodyWashReducer,
        rating: ratingReducer,
    },
});

export default store;