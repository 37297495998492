import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { useDispatch, useSelector } from "react-redux" 
import { LoginAfterCart } from "../Store/Slices/cartSlice"
import ProfileManager from "../Components/Large/layouts/Navbar/ProfileManager"


export const CreateOrder = async (amount) => {
    return await axios.post(`${process.env.REACT_APP_APIURL}createOrder`, amount)
        .then((res) => res.data)
}
// Avidev
export const UserupdatePassword = async (id) => {
    return await axios
    .post(`${process.env.REACT_APP_APIURL}userupdatepassword`, id)
        .then((res) => res.data.CustomerEdit)
};
// Avidev 7-11-2024 proile details
export const profileDetals = async (data) => {
    return await axios.post(`${process.env.REACT_APP_APIURL}profiledetails`, data)
        .then((res) => res.data.CustomerEditDetails)
}

//Vishal29.01.25
export const fetchPhoneNumber = async (userId) => {
    return await axios
        .post(`${process.env.REACT_APP_APIURL}fetch-phone-number`, { userId })  // Ensure this matches your backend URL
        .then((res) => res.data.details[0].phone_number)  // Make sure the response data matches
        .catch((err) => {
            console.error("Error fetching phone number:", err);
        });
};

//Vishal 10.02.25
// export const HandWashProduct = createAsyncThunk('products/fetchHandWash', async () => {
//     return await axios
//         .post(`${process.env.REACT_APP_APIURL}handwash.php`)
//         .then((response) => {
//             if (response.data === "false") {
//                 throw new Error("No product found for Hand Wash");
//             }
//             return response.data;
//         });
// });
export const HandWashProduct = createAsyncThunk("handWash/fetchHandWash", async (_, { rejectWithValue }) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_APIURL}handwash`, {}, 
        {
            headers: {
                "Content-Type": "application/json"
            }
        });

        //console.log("API Response Data:", response.data); // Debugging API Response

        if (!response.data || !Array.isArray(response.data.product)) {
            throw new Error("Invalid response format");
        }

        return response.data.product; // Ensure we only return the array
    } catch (error) {
        console.error("API Error:", error.response ? error.response.data : error.message);
        return rejectWithValue(error.response ? error.response.data : "Server Error");
    }
});

export const ToiletCleanerProduct = createAsyncThunk("toiletCleaner/fetchToiletCleaner", async (_, { rejectWithValue }) => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_APIURL}toiletcleaner`, {},
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
  
        if (!response.data || !Array.isArray(response.data.product)) {
          throw new Error("Invalid response format");
        }
  
        return response.data.product; // Ensure we only return the array
      } catch (error) {
        console.error("API Error:", error.response ? error.response.data : error.message);
        return rejectWithValue(error.response ? error.response.data : "Server Error");
      }
    }
);

export const SurfaceCleanerProduct = createAsyncThunk("surfaceCleaner/fetchSurfaceCleaner", async (_, { rejectWithValue }) => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_APIURL}surfaceCleaner`, {},
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
  
        if (!response.data || !Array.isArray(response.data.product)) {
          throw new Error("Invalid response format");
        }
  
        return response.data.product; // Ensure we only return the array
      } catch (error) {
        console.error("API Error:", error.response ? error.response.data : error.message);
        return rejectWithValue(error.response ? error.response.data : "Server Error");
      }
    }
);
export const DishWashGelProduct = createAsyncThunk("dishWashGel/fetchDishWashGel", async (_, { rejectWithValue }) => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_APIURL}dishWashGel`, {},
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
  
        if (!response.data || !Array.isArray(response.data.product)) {
          throw new Error("Invalid response format");
        }
  
        return response.data.product; // Ensure we only return the array
      } catch (error) {
        console.error("API Error:", error.response ? error.response.data : error.message);
        return rejectWithValue(error.response ? error.response.data : "Server Error");
      }
    }
);
export const SanitizerGelProduct = createAsyncThunk("sanitizerGel/fetchSanitizerGel", async (_, { rejectWithValue }) => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_APIURL}sanitizerGel`, {},
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
  
        if (!response.data || !Array.isArray(response.data.product)) {
          throw new Error("Invalid response format");
        }
  
        return response.data.product; // Ensure we only return the array
      } catch (error) {
        console.error("API Error:", error.response ? error.response.data : error.message);
        return rejectWithValue(error.response ? error.response.data : "Server Error");
      }
    }
);
export const BodyWashProduct = createAsyncThunk("bodyWash/fetchBodyWash", async (_, { rejectWithValue }) => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_APIURL}bodyWash`, {},
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
  
        if (!response.data || !Array.isArray(response.data.product)) {
          throw new Error("Invalid response format");
        }
  
        return response.data.product; // Ensure we only return the array
      } catch (error) {
        console.error("API Error:", error.response ? error.response.data : error.message);
        return rejectWithValue(error.response ? error.response.data : "Server Error");
      }
    }
);

export const FloorCleanerProduct = createAsyncThunk("floorCleaner/fetchFloorCleaner", async (_, { rejectWithValue }) => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_APIURL}floorCleaner`, {},
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
  
        if (!response.data || !Array.isArray(response.data.product)) {
          throw new Error("Invalid response format");
        }
  
        return response.data.product; // Ensure we only return the array
      } catch (error) {
        console.error("API Error:", error.response ? error.response.data : error.message);
        return rejectWithValue(error.response ? error.response.data : "Server Error");
      }
    }
);

//Vishal 24.02.25: For state and city retrevial from pin code
export const getLocationByPincode = async (pincode) => {
  const url = `https://nominatim.openstreetmap.org/search?postalcode=${pincode}&country=India&format=json`;
  return await axios.get(url);
};

//vishal 26.02.2025
export const submitRatings = createAsyncThunk(
  "addrating/submitRating",
  async (ratingData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_APIURL}addrating`,
        ratingData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // console.log("API Response:", response.data); // Debugging log

      if (!response.data || typeof response.data !== "object" || !response.data.status) {
        throw new Error(response.data?.message || "Invalid response format");
      }

      return response.data;
    } catch (error) {
      console.error(
        "API Error:",
        error.response ? error.response.data : error.message
      );
      return rejectWithValue(
        error.response ? error.response.data : "Server Error"
      );
    }
  }
);



// Fetch ratings for a product
export const fetchRatings = createAsyncThunk(
  "rating/fetchRatings",
  async (productId, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_APIURL}getrating`,
        { product_id: productId },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // console.log("API Response:", response.data); // Debugging log

      if (!response.data || typeof response.data !== "object" || !response.data.status) {
        throw new Error(response.data?.message || "Invalid response format");
      }

      return response.data.ratings || []; // Ensure an array is returned
    } catch (error) {
      console.error(
        "API Error:",
        error.response ? error.response.data : error.message
      );
      return rejectWithValue(
        error.response ? error.response.data : "Server Error"
      );
    }
  }
);

// Update ratings for a product
export const updateRatings = createAsyncThunk(
  "updaterating/updateRatings",
  async (ratingData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_APIURL}updaterating`,
        ratingData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // console.log("API Response:", response.data); // Debugging log

      if (!response.data || typeof response.data !== "object" || !response.data.status) {
        throw new Error(response.data?.message || "Invalid response format");
      }

      return response.data;
    } catch (error) {
      console.error(
        "API Error:",
        error.response ? error.response.data : error.message
      );
      return rejectWithValue(
        error.response ? error.response.data : "Server Error"
      );
    }
  }
);




//Vishal 28-01-2025

// export const profileDetals = async (data) => {
//     return await axios
//       .post(`${process.env.REACT_APP_APIURL}profiledetails`, data)
//       .then((res) => {
//         console.log("API Response Data:", res.data);  // Log the response data to check its structure
//         return res.data.CustomerEditDetails;
//       });
// };
  
export const youraddress = async (data) => {//Avidev 7-11-2024 proile details
    return await axios.post(`${process.env.REACT_APP_APIURL}userAddress`, data)
        .then((res) => res.data.Youraddress)
}
// Avidev
// export const customerbyId = async (id) => {
//     return await axios
//         .post(`${process.env.REACT_APP_APIURL}customeredit`, id)
//         .then((response) => response.data.CustomerEdit)
// };
export const userResetPassword = async (phone) => {//1st step of reset password
    return await axios.post(`${process.env.REACT_APP_APIURL}userresetPassword`,  phone,{cache: {
        methods: ['post']
      }})
        .then((res) => res.data)
};
export const getOrderDetails = async (phone) => {
    return await axios.post(`${process.env.REACT_APP_APIURL}getorderdetails`,  phone,{cache: {
        methods: ['post']
      }})
        .then((res) => res.data)
};
export const CreateSigneture = async (signeture) => {
    return await axios.post(`${process.env.REACT_APP_APIURL}createSigneture`, signeture)
        .then((res) => res.data)
}
export const Adcontact = async (contact) => {
    return await axios.post(`${process.env.REACT_APP_APIURL}addContact`, contact)
        .then((res) => res.data)
}
export const AddAddress = async (address) => {
    return await axios.post(`${process.env.REACT_APP_APIURL}addAddress`, address)
        .then((res) => res.data)
}
export const SignupUser = async (values) => {
    return await axios.post(`${process.env.REACT_APP_APIURL}site_user`, values)
        .then((res) => res.data)
}
export const Placeorder = async (values) => {
    return await axios.post(`${process.env.REACT_APP_APIURL}caseonorder`, values)
        .then((res) => res.data)
}
export const orderhistory = async (data) => {
    return await axios.post(`${process.env.REACT_APP_APIURL}orderhistory`, data)
        .then((res) => res.data.orderhistory)
}
// Avidev 5-31-2024
export const orderhistorydetails = async (id) => {
    return await axios.post(`${process.env.REACT_APP_APIURL}orderhistorydetails`, id)
        .then((res) => res.data)
}
export const orderCommand = async (data) => {
    return await axios
        .post(`${process.env.REACT_APP_APIURL}commands`, data)
        .then((response) => response.data.orderdata)
};
export const fetchorderDetails = async (id) => {
    return await axios
        .post(`${process.env.REACT_APP_APIURL}orderDetails`, id)
        .then((response) => response.data)
}
// Avidev 7-25-2024
export const DeleteAddress = async (id) => {
    return await axios
        .post(`${process.env.REACT_APP_APIURL}deleteaddress`, id)
        .then((response) => response.data.CustomerEdit)
};
export const updateAddressById = async (id) => { // aditya
    return await axios 
        .post(`${process.env.REACT_APP_APIURL}updateaddress`, id)
        .then((response) => response.data)
};


// export const customerfetch = async () => {
//     return await axios
//         .post(`${process.env.REACT_APP_APIURL}customer`)
//         .then((response) => response.data.Customer_order_details)
// };
export const GetCartDetails = async (id) => {
    return await axios.post(`${process.env.REACT_APP_APIURL}cartdetails`, id)
        .then((res) => res.data)
}
export const GetHistoryDetails = async (id) => {
    return await axios.post(`${process.env.REACT_APP_APIURL}historydetails`, id)
        .then((res) => res.data)
}
// export const AddWishlist = async (wishListData) => {
//     return await axios.post(`${process.env.REACT_APP_APIURL}addwishlist`, wishListData)
//         .then((res) => res.data)
// }
// export const RemoveWishlist = async (values) => {
//     return await axios.post(`${process.env.REACT_APP_APIURL}removewishlist`, values)
//         .then((res) => res.data)
// }
// Api for redux store sclices start 
export const offeredBrands = createAsyncThunk('brand/offerbrands', async () => {
    return await axios
        .post(`${process.env.REACT_APP_APIURL}featuredbrand`,{cache: {
            methods: ['post']
          }})
        .then((response) => response.data)
});
export const PushUserWishlist = createAsyncThunk('wishlist/wishlistdetails', async (productdetails) => {
    return await axios
        .post(`${process.env.REACT_APP_APIURL}addwishlist`, productdetails,{cache: {
            methods: ['post']
          }})
        .then((response) => response.data)
});
export const GetuserWishlist = createAsyncThunk('wishlist/wishlistdetails', async (User_ID) => {
    return await axios
        .post(`${process.env.REACT_APP_APIURL}getuserwishlist`,User_ID,{cache: {
            methods: ['post']
          }})
        .then((response) => response.data)
});
export const fatchSizes = createAsyncThunk('size/sizedetails', async () => {
    return await axios
        .post(`${process.env.REACT_APP_APIURL}size`,{cache: {
            methods: ['post']
          }})
        .then((response) => response.data)
});


export const fetchBanner = createAsyncThunk('banner/fetchbanner', async () => {
    return await axios
        .post(`${process.env.REACT_APP_APIURL}fatch_baner`,{cache: {
            methods: ['post']
          }})
        .then((response) => response.data)
});
export const fetchCategory = createAsyncThunk('category/fetchCategory', async () => {
    return await axios
        .post(`${process.env.REACT_APP_APIURL}fatch_category`,{cache: {
            methods: ['post']
          }})
        .then((response) => response.data) 
});
export const getAddress = createAsyncThunk('user/address', async (id) => {
    return await axios
        .post(`${process.env.REACT_APP_APIURL}getAddress`, id,{cache: {
            methods: ['post']
          }})
        .then((response) => response.data)
});
export const magnifying = createAsyncThunk('images/magnifying', async (id) => {
    return await axios
        .post(`${process.env.REACT_APP_APIURL}magnifying`, id,{cache: {
            methods: ['post']
          }})
        .then((response) => response.data)
});
export const productData = createAsyncThunk('product/productdetails', async (id) => {
    return await axios
        .post(`${process.env.REACT_APP_APIURL}productdetails`, id,{cache: {
            methods: ['post']
          }})
        .then((response) => response.data)
});
export const AllProducts = createAsyncThunk('products/fetchProducts', async () => {
    return await axios
        .post(`${process.env.REACT_APP_APIURL}products`,{cache: {
            methods: ['post']
          }})
        .then((response) => response.data)
});
export const fetchUsers = createAsyncThunk('user/fetchUsers', async (login) => {
    return await axios
        .post(`${process.env.REACT_APP_APIURL}login_user`, login,{cache: {
            methods: ['post']
          }})
        .then((response) => response.data)
});
export const PushUserCart = async (productdetails, userId) => {

    let productId = productdetails.map((item) => item.id);
    let names = productdetails.map((item) => item.name);
    let cartQuantity = productdetails.map((item) => item.cartQuantity);
    let itemSize = productdetails.map((item) => item.itemSize);
    console.log(productdetails.length)
    console.log(productId)
    if (productdetails.length !== 0) {

        await axios
            .post(`${process.env.REACT_APP_APIURL}usercart`, [productId, names, cartQuantity, itemSize, userId])
            .then((req, res) => {
                console.log(`done`);
            })
            .catch((err) => {
                console.log(err);
            });
    }
    const allproducts = useSelector((state) => state.product);
    const products = useSelector((state) => state.productdetails);
    const sizedetails = useSelector((state) => state.size);
    const dispatch = useDispatch();

    GetCartDetails(sessionStorage.getItem('___user')).then((res) => {
        //console.log(product)

        const { product } = allproducts.products;
        const { details } = products.productdetails;
        const { size } = sizedetails.sizes;
        res.cartItems.map((items) => {
            const findIndex = product !== undefined ? product.findIndex((item) => item.id === items.product_id) : null;
            //console.log(items)
            // cart.push(product[findIndex])
            // localStorage.setItem(`cartItems`, JSON.stringify(cart))
            // get the all details belongs to product Id from product entry tables
            const itemIndex = details !== undefined ? details.filter((item) => item.product_id === items.product_id) : [];
            const Productvariants = [];
            console.log(itemIndex);
            if (itemIndex.length !== 0 && details !== undefined && size !== undefined) {
                itemIndex.map((item, idx) => {
                    const index = size.filter((items) => items.id === item.size_id)
                    const values = {
                        "price": item.price,
                        "size": index[0]['size_value']
                    }
                    Productvariants.push(values)
                })
                const productDetails = Object.assign({ price: product[findIndex].default_price }, product[findIndex]);
                dispatch(LoginAfterCart([productDetails, product[findIndex].default_size, Productvariants]));
            }
            
        })
        //console.log(cart)
    })
};
export const pushUsers = createAsyncThunk('user/login_push_user', async (login) => {
    return await axios
        .post(`${process.env.REACT_APP_APIURL}login_push_user`, login,{cache: {
            methods: ['post']
          }})
        .then((response) => response.data)
});

export const UnsubscribeEmail= async (email)=>{
    return await axios
            .post(`${process.env.REACT_APP_APIURL}unsubscribe`, email,{cache: {
                methods: ['post']
              }})
            .then((response) => response.data)
}
export const recentOrder= async (paymentID)=>{
    return await axios
            .post(`${process.env.REACT_APP_APIURL}currentorder`, paymentID,{cache: {
                methods: ['post']
              }})
            .then((response) => response.data)
}
export const currentUser= createAsyncThunk('user/login_push_user', async (ACCESSTOKEN)=>{
    return await axios
            .post(`${process.env.REACT_APP_APIURL}currentuser`, ACCESSTOKEN,{cache: {
                methods: ['post']
              }})
            .then((response) => response.data)
});
// Api for redux store sclices end
