import styled from '@emotion/styled';
import { Box, Button, CardContent, Collapse, Divider, Typography, Modal, LinearProgress } from '@mui/material';
import Rating from '@mui/material/Rating';
import React, { useState, useEffect } from 'react';
import StarIcon from '@mui/icons-material/Star';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import { fetchRatings, submitRatings, updateRatings } from '../../../Api/Api'
import { useDispatch, useSelector } from 'react-redux'; 
import { resetRatings } from "../../../Store/Slices/ratingSlice";

const stylemod = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw', 
  maxWidth: 1200,
  height: 'auto',
  maxHeight: 450,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,

  // Media Queries for Different Screens
  '@media (max-width: 1024px)': { // medium screens
    maxWidth: 900,
    maxHeight: 400,
  },

  '@media (max-width: 768px)': { // Small Tablets & Large Phones
    width: '90vw',
    maxWidth: 600,
    maxHeight: 'auto',
    p: 3,
  },

  '@media (max-width: 480px)': { // Mobile Screens
    display: 'table',
    width: '95vw',
    maxWidth: 360,
    height: 'auto',
    p: 2,
  }
};


const Rattingcont = styled(Box)`
  color:#916fda;
  font-size:50px;
  border-radius:3px;
  display:flex;
  flex-direction:row;
  flex-wrap:nowrap;
  justify-content:space-between;
  align-items:center;
  padding:15px;
  margin-left:15px;
  @media (max-width: 768px) {
    flex-direction:column;
    gap:0px;
  };
`;

const RatingBox = styled(Box)`
  width:31%;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    width:100%;
  };
`;

const RatingButton = styled(Button)({
  textTransform: 'none',
  fontSize: '12px',
  backgroundColor: '#916fda',
  color: 'white',
  fontWeight: '600',
  marginRight: '10px',
  padding: '0.5rem 2.5rem',
  '&:hover': {
    backgroundColor: '#916fda',
  }
});

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
}));

const Ratingpage = ({ product_id }) => {
  const loggedInUserId = sessionStorage.getItem('___user'); // Fetch login user ID
  const dispatch = useDispatch();
  // console.log(loggedInUserId);
  
  const { ratings } = useSelector(state => state.rating) || { ratings: [] };
  
  
  const [averageRating, setAverageRating] = useState(4.5); // Default rating to 4.5
  const [openmodRev, setOpenRev] = useState(false);
  const [ratingvalue, setRatingValue] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const [existingReview, setExistingReview] = useState(null);
  // const [reviews, setReviews] = useState([]);

  const calculateStarDistribution = (ratings) => {
    if (!ratings || ratings.length === 0) return [];
  
    const totalRatings = ratings.length;
    const starCounts = { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 };
  
    ratings.forEach((review) => {
      const star = Math.round(parseFloat(review.rating)); // Round rating to nearest integer
      if (star >= 1 && star <= 5) {
        starCounts[star]++;
      }
    });
  
    return Object.keys(starCounts).map((star) => ({
      stars: parseInt(star),
      percent: ((starCounts[star] / totalRatings) * 100).toFixed(1),
    }));
  };
  
  useEffect(() => {
    if (ratings.length > 0) {
      setAverageRating(calculateAverageRating(ratings)); // Update average rating
      setStarDistribution(calculateStarDistribution(ratings)); // Update star percent
    }
  }, [ratings]);
  
  // State to store the updated rating distribution
  const [starDistribution, setStarDistribution] = useState([
    { stars: 5, percent: 0 },
    { stars: 4, percent: 0 },
    { stars: 3, percent: 0 },
    { stars: 2, percent: 0 },
    { stars: 1, percent: 0 },
  ]);
  

  // console.log("Fetching reviews for product_id:", product_id);
  // console.log("Submitting review for product_id:", product_id);

  useEffect(() => {
    if (product_id) {
      dispatch(resetRatings()); 
      dispatch(fetchRatings(product_id)); 

      // Reset modal state when switching products
      setExistingReview(null);
      setRatingValue(0);
      formik.setValues({ userReview: '' });

      // Reset star distribution when switching products
      setStarDistribution([
        { stars: 5, percent: 0 },
        { stars: 4, percent: 0 },
        { stars: 3, percent: 0 },
        { stars: 2, percent: 0 },
        { stars: 1, percent: 0 },
      ]);
    }
  }, [dispatch, product_id]);

  useEffect(() => {
    // console.log("Fetched Ratings:", ratings);
    if (ratings.length > 0) {
      const userReview = ratings.find(review => review.user_id === loggedInUserId);
      if (userReview) {
        setRatingValue(userReview.rating);
      }
    }
  }, [ratings, loggedInUserId]);


  useEffect(() => {
    if (ratings.length > 0) {
      const userReview = ratings.find(review => review.user_id === loggedInUserId);
      if (userReview) {
        setExistingReview(userReview);
        setRatingValue(userReview.rating);
        formik.setValues({ userReview: userReview.comments });
      } else {
        setExistingReview(null);
      }
    }
  }, [ratings, loggedInUserId]);


  // useEffect(() => {
  //   console.log("Product ID from URL:", product_id);
  // }, [product_id]);

  const formik = useFormik({
    initialValues: {
      userReview: ''
    },
    onSubmit: async (values, { resetForm, setErrors }) => {
      if (!loggedInUserId) {
        setErrors({ userReview: 'You must be logged in to submit a review.' });
        return;
      }
      const ratingData = {
        product_id: product_id,
        user_id: loggedInUserId,
        rating: ratingvalue,
        comments: values.userReview
      };

      try {
        // await dispatch(submitRatings(ratingData)).unwrap();
          if (existingReview) {
            // Update the existing review
            await dispatch(updateRatings(ratingData)).unwrap();
          } else {
            // Submit a new review
            await dispatch(submitRatings(ratingData)).unwrap();
          }
        resetForm();
        setOpenRev(false);
        dispatch(fetchRatings(product_id)); // Refresh ratings after submission
      } catch (error) {
        // console.error("Error submitting rating:", error);
        setErrors({ userReview: 'An error occurred while submitting your review. Please try again.' });
      }
    },
    validate: values => {
      let errors = {};
      if (!values.userReview) {
        errors.userReview = 'Review cannot be empty!';
      }
      return errors;
    },
  });

  // Calculate Average Rating based on star formula
  const calculateAverageRating = (ratings) => {
    if (!ratings || ratings.length === 0) return 4.5; // No ratings- return 4.5
  
    let totalRatings = ratings.length;
    let totalStars = ratings.reduce((sum, r) => sum + parseFloat(r.rating), 0);
    
    let avgRating = totalStars / totalRatings;
    
    return avgRating > 5 ? 5 : avgRating.toFixed(1); // Ensure max 5 stars
  };
  
  useEffect(() => {
    setAverageRating(calculateAverageRating(ratings)); // Update when ratings change
  }, [ratings]); // Dependency on ratings ensures updates
  
  return (
    <Box sx={{ width: '100vw', overflowX: 'hidden' }}>
      <Box style={{ width: '100%', borderBottom: '2px solid #f4dee1' }}>
        <Typography variant='h2' style={{ fontSize: '20px', fontWeight: '600', padding: '10px' }}>
          Rating & Reviews
        </Typography>
      </Box>

      <Rattingcont>
        {/* Rating Summary */}
        <RatingBox key={product_id}>
          <Typography variant='h2' style={{ fontSize: '50px' }}>
            {averageRating} 
            <StarIcon style={{ fontSize: '30px' }} />
          </Typography>
          <Typography variant='subtitle2'>
            {ratings.length} Rating{ratings.length !== 1 ? 's' : ''} & {ratings.length} Review{ratings.length !== 1 ? 's' : ''}
          </Typography>
        </RatingBox>

        {/* User Rating */}
        <RatingBox>
          <Rating name="read-only" value={parseFloat(averageRating)} readOnly />
          <Typography variant='subtitle2'>Ratings out of 5</Typography>
        </RatingBox>

        {/* Write a Review Button */}
        <RatingBox>
          {/* <RatingButton onClick={() => setOpenRev(true)}>Write a Review</RatingButton> */}
          <RatingButton onClick={() => setOpenRev(true)}>
            {existingReview ? 'Edit Your Review' : 'Write a Review'}
          </RatingButton>
          <Modal open={openmodRev} onClose={() => setOpenRev(false)}>
            <Box sx={stylemod}>
              <div style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                {/* <Typography variant="h6" style={{ fontWeight: 'bold', fontSize: '1.7rem' }}>
                  Rate and Review this Product
                </Typography> */}
                <CloseIcon fontSize="medium" onClick={() => setOpenRev(false)} style={{ cursor: 'pointer', float: 'right' }} />
              </div>

              {/* <form onSubmit={formik.handleSubmit}>
                <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography sx={{ display: 'flex', alignItems: 'center' }}> */}
                      {/* <p style={{ fontSize: '3.5rem', fontWeight: 'semibold' }}>{ratingvalue}</p> */}
                      {/* <p style={{ fontSize: '3.5rem', fontWeight: 'semibold' }}>
                        {ratingvalue ? parseFloat(ratingvalue).toFixed(1) : "0.0"}
                      </p>

                      <StarIcon style={{ fontSize: '60px', color: '#916fda' }} />
                    </Typography>
                    <Rating
                      name="simple-controlled"
                      value={ratingvalue || 0}
                      onChange={(event, newValue) => setRatingValue(newValue)}
                    />
                  </Box>
                  <TextareaAutosize
                    name='userReview'
                    onChange={formik.handleChange}
                    value={formik.values.userReview}
                    placeholder="Your valuable review here"
                    style={{ width: 700, height: 185, resize: 'none', fontSize: '1rem', padding: '1rem', border: '1px solid #916FDA' }}
                  />
                </Box>
                {formik.errors.userReview && <div style={{ color: 'red', fontStyle: 'italic' }}>{formik.errors.userReview}</div>}
                <RatingButton type='submit' style={{ marginTop: '10px', padding: '0.5rem 4rem' }}>Submit</RatingButton>
              </form> */}

            <form onSubmit={formik.handleSubmit}>
              {/* Parent Box with Two Sections */}
              <Box sx={{ display: 'flex', gap: 4, mt: 2, flexDirection: { xs: 'column', sm: 'row' }}}>

                {/* Left Side: Rating Breakdown */}
                <Box sx={{ width: 250 }}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                    Customer Reviews
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography sx={{ fontSize: '2.5rem', fontWeight: 'bold' }}>
                      {ratingvalue ? parseFloat(ratingvalue).toFixed(1) : "0.0"}
                    </Typography>
                    <StarIcon sx={{ fontSize: 50, color: '#faaf00' }} />
                  </Box>
                  <Typography sx={{ fontSize: '0.9rem', color: 'gray' }}>
                    {ratings.length} customer ratings {averageRating} out of 5.0
                  </Typography>

                  {starDistribution.slice().reverse().map(({ stars, percent }) => (
                    <Box key={stars} sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
                      <Typography sx={{ fontSize: '0.9rem', color: 'gray' }}>{stars} Star</Typography>
                      <LinearProgress
                        variant="determinate"
                        value={percent}
                        sx={{
                          width: 120,
                          height: 8,
                          borderRadius: 4,
                          backgroundColor: '#e0e0e0',
                          '& .MuiLinearProgress-bar': { backgroundColor: '#faaf00' },
                        }}
                      />
                      <Typography sx={{ fontSize: '0.8rem', color: 'gray' }}>{percent}%</Typography>
                    </Box>
                  ))}

                </Box>
                
                {/* Right Side: Review Form */}
                <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                  <Typography sx={{ mb: 1, fontWeight: 'bold' }}>Leave Your Review</Typography>
                  <Rating
                    name="simple-controlled"
                    value={ratingvalue || 0}
                    onChange={(event, newValue) => setRatingValue(newValue)}
                  />
                  <textarea
                    name="userReview"
                    onChange={formik.handleChange}
                    value={formik.values.userReview}
                    placeholder="Your valuable review here"
                    style={{
                      width: '100%',
                      height: 150,
                      resize: 'none',
                      fontSize: '1rem',
                      padding: '1rem',
                      border: '1px solid #916FDA',
                      marginTop: '10px',
                    }}
                  />
                  {formik.errors.userReview && (
                    <div style={{ color: 'red', fontStyle: 'italic', marginTop: '5px' }}>
                      {formik.errors.userReview}
                    </div>
                  )}
                  <button type="submit" style={{ marginTop: '10px', padding: '0.5rem 4rem', backgroundColor: '#916fda', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
                    Submit
                  </button>
                </Box>
              </Box>
            </form>
            </Box>
          </Modal>
        </RatingBox>
      </Rattingcont>

      <Divider />

      {/* Reviews List */}
      <Box>
        <Typography variant='h2' style={{ fontSize: '16px', fontWeight: '600', padding: '10px' }}>
          Top Reviews
          {/* Expandable Button */}
          <ExpandMore expand={expanded} onClick={() => setExpanded(!expanded)}>
            <ExpandMoreIcon />
          </ExpandMore>
        </Typography>

        

        <Collapse in={expanded} timeout="auto" unmountOnExit> {/* **Added Collapse Here** */}
          {ratings.length === 0 ? (
            <Typography variant='body2'>No reviews yet. Be the first to review!</Typography>
          ) : (
            ratings.map(review => (
              // console.log(review),
              
              // <Typography key={review.id} style={{ padding: '10px' }}>
              //   {review.f_name} - {review.comments} - {new Date(review.created_at).toLocaleDateString()}
              //   <RatingBox>
              //     <Rating name="read-only" value={parseFloat(review.rating)} readOnly />
              //   </RatingBox>
              // </Typography>
              <Box
                key={review.id}
                sx={{
                  display: 'grid',
                  gridTemplateColumns: { xs: '1fr', md: '200px 1fr' }, // Stacks on small screens, table-like on large
                  alignItems: 'center',
                  gap: 2,
                  width: { xs: 340, sm: '100%' }, // 340px on small screens, full width otherwise
                  p: 2,
                  borderBottom: '1px solid #e0e0e0',
                }}
              >
                {/* Left Column (User Name) */}
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {review.f_name}
                </Typography>
              
                {/* Right Column (Rating + Date + Review) */}
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>

                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Rating name="read-only" value={parseFloat(review.rating)} readOnly />

                    <Typography variant="body2" sx={{ color: 'gray' }}>
                      {new Date(review.created_at).toLocaleDateString()}
                    </Typography>
                  </Box>

                  <Typography variant="body2">{review.comments}</Typography>
                </Box>
              </Box>

            ))
          )}
        </Collapse> 
      </Box>
    </Box>
  );
};

export default Ratingpage;