import { createSlice } from "@reduxjs/toolkit";
import { fetchRatings } from "../../Api/Api";

const ratingSlice = createSlice({
  name: "rating",
  initialState: {
    ratings: [],
    loading: false,
    error: null,
  },
  reducers: {
    resetRatings: (state) => {
      state.ratings = []; // Clear ratings when switching products
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRatings.pending, (state) => {
        // console.log("Fetching ratings...");
        state.loading = true;
        state.error = null;
        state.ratings = []; // Reset ratings while fetching to prevent old data
      })
      .addCase(fetchRatings.fulfilled, (state, action) => {
        // console.log("Ratings fetched successfully:", action.payload); // Debug log
        state.ratings = action.payload;
        state.loading = false;
      })
      .addCase(fetchRatings.rejected, (state, action) => {
        // console.error("Failed to fetch ratings:", action.payload);
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export the action to reset ratings
export const { resetRatings } = ratingSlice.actions;
export default ratingSlice.reducer;



// import { createSlice } from "@reduxjs/toolkit";
// import { fetchRatings } from "../../Api/Api"; 

// const ratingSlice = createSlice({
//   name: "rating",
//   initialState: {
//     ratings: [],
//     loading: false,
//     error: null,
//   },
//   reducers: {},

//   extraReducers: (builder) => {
//     builder
//       .addCase(fetchRatings.pending, (state) => {
//         console.log("Fetching ratings...");
//         state.loading = true;
//         state.error = null;
//       })
//       .addCase(fetchRatings.fulfilled, (state, action) => {
//         console.log("Ratings fetched successfully:", action.payload); // Debug log
//         state.ratings = action.payload;
//         state.loading = false;
//       })
//       .addCase(fetchRatings.rejected, (state, action) => {
//         console.error("Failed to fetch ratings:", action.payload);
//         state.loading = false;
//         state.error = action.payload;
//       });
//   },
// });

// export default ratingSlice.reducer;
